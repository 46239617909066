export const UPLOAD_IMAGE_FILE_FORMATS = [
  "image/jpeg",
  "image/jpg",
  "image/pjpeg",
  "image/png",
  "image/bmp",
];

export const UPLOAD_IMAGE_EXTENSION = ["jpeg", "jpg", "pjpeg", "png", "bmp"];

export const UPLOAD_VIDEO_FILE_FORMATS = [
  "video/mp4",
  "video/mpeg",
  "video/x-msvideo",
  "video/avi",
  "video/quicktime",
  "application/octet-stream",
  "video/ogg",
  "audio/ogg",
];

export const UPLOAD_VIDEO_EXTENSION = ["mp4", "mpeg", "avi", "mov", "ogg"];

export const FILE_VERIFICATION_FORMATS = [
  "image/jpeg",
  "image/jpg",
  "image/pjpeg",
  "image/png",
  "application/pdf",
  "image/heic",
  "image/heic-sequence",
];

export const VERIFICATION_UPLOAD_MAX_FILES = 6;

export const COMMUNICATION_UPLOAD_MAX_FILES = 5;
export const PROFILE_UPLOAD_MAX_PHOTOS = 5;
export const PROFILE_UPLOAD_MAX_VIDEOS = 5;

// Video max width
export const MEDIA_UPLOAD_MAX_VIDEO_WIDTH = 3840;
// Video max height
export const MEDIA_UPLOAD_MAX_VIDEO_HEIGHT = 2160;

// # Image size ~10Mb
export const UPLOAD_MAX_IMAGE_FILE_SIZE = 10485760;
// # Video size ~50Mb
export const UPLOAD_MAX_VIDEO_FILE_SIZE = 52428800;

export const FILE_VERIFICATION_MAX_SIZE = 5242880;
export const MEDIA_1_MEGA_BYTE = 1048576;

export const FILE_TMP_UPLOADED = 0;
export const FILE_TMP_WAITING_UPLOAD = 1;
export const FILE_TMP_SEND = 2;
export const FILE_TMP_BROKEN = 3;

export const UPLOAD_MEDIA_SCOPE_WIZARD = "wizard";
export const UPLOAD_MEDIA_SCOPE_TRUSTED = "trusted";
export const UPLOAD_MEDIA_SCOPE_PROFILE = "profile";

/**
 * Convert bytes to MB
 * @param {number} byteValue Value in bytes
 * @param {number} fractionDigits Count of decimals
 * @return {string}
 */
export const getSizeInMb = (byteValue, fractionDigits = 0) => {
  return (byteValue / MEDIA_1_MEGA_BYTE).toFixed(fractionDigits);
};
